<template>
	<div class="page">
		<div style="width: 66%; margin: 0 auto">
			<div class="slougan-list">
				<div class="slougan" @click="router.push('/selectScene')">
					<img src="../images/left_icon.png" alt="" srcset="" class="icon" />
					<div class="icon-text1">15年</div>
					<div class="icon-text2">专注硬件深研</div>
				</div>
				<div class="slougan" @click="router.push('/selectScene')">
					<img src="../images/center_icon.png" alt="" srcset="" class="icon" />
					<div class="icon-text1">4v1</div>
					<div class="icon-text2">金牌服务对接</div>
				</div>
				<div class="slougan" @click="router.push('/selectScene')">
					<img src="../images/right_icon.png" alt="" srcset="" class="icon" />
					<div class="icon-text1">10万</div>
					<div class="icon-text2">小时无故障测试</div>
				</div>
			</div>
			<div class="goods-type">
				<div class="goods-type-workstation" @click="router.push('/desktop/4')">
					<div class="goods-type-text1">工作站</div>
					<div class="goods-type-text2">性能巨兽 内藏无限创造力</div>
					<div class="goods-type-button">查看详情</div>
				</div>
				<div class="goods-type-desktop" @click="router.push('/desktop/2')">
					<div class="goods-type-text1">台式机</div>
					<div class="goods-type-text2">专注生产办公</div>
					<div class="goods-type-button">查看详情</div>
				</div>
				<div class="goods-type-server" @click="router.push('/desktop/9')">
					<div class="goods-type-text1">服务器</div>
					<div class="goods-type-text2">GPU算力解锁</div>
					<div class="goods-type-button">查看详情</div>
				</div>
				<div class="goods-type-onedesktop" @click="router.push('/desktop/5')">
					<div class="goods-type-text1">一体机</div>
					<div class="goods-type-text2">省空间更美观</div>
					<div class="goods-type-button">查看详情</div>
				</div>
				<div class="goods-type-notebook" @click="router.push('/desktop/3')">
					<div class="goods-type-text1">笔记本</div>
					<div class="goods-type-text2">高效视听体验</div>
					<div class="goods-type-button">查看详情</div>
				</div>
			</div>
			<img
					src="../images/custormized.png"
					alt=""
					style="height: auto; width: 100%;cursor: pointer"
					@click="router.push('/Customize')"
			/>
		</div>
		<div class="aftersale" @click="router.push('/afterDelivery')">
			<div class="aftersale-container">
				<img src="../images/title1.png" alt="" class="aftersale-img" />
				<div class="aftersale-text">15年行业积累，深耕硬件和主机领域</div>
				<div class="aftersale-list">
					<div class="aftersale-li">
						<img src="../images/aftersale_left.png" alt="" />
						<div class="aftersale-li-text1">7*24小时</div>
						<div class="aftersale-li-text2">
							15年行业积累，深耕硬件和主机领域
						</div>
					</div>
					<div class="aftersale-li">
						<img src="../images/aftersale_center.png" alt="" />
						<div class="aftersale-li-text1">3年上门</div>
						<div class="aftersale-li-text2">
							2H响应 48H上门 3年保修 上门无忧
						</div>
					</div>
					<div class="aftersale-li">
						<img src="../images/aftersale_right.png" alt="" />
						<div class="aftersale-li-text1">SOP测试</div>
						<div class="aftersale-li-text2">
							严格36道NPI测试 确保产品稳定及兼容
						</div>
					</div>
				</div>
			</div>
		</div>
		<img src="../images/aboutus.png" alt="" class="about-us" @click="router.push('/aboutUs')"/>
		<div class="case">
			<img src="../images/case.png" alt="" />
			<div class="case-title">15年行业积累，深耕硬件和主机领域</div>
		</div>
		<div style="width: 100%;background-color:#fff;">
			<swiper
					:slidesPerView="1.6"
					:spaceBetween="32"
					:loop="true"
					:centeredSlides="true"
					:pagination="{ clickable: true }"
					:modules="modules"
					:initialSlide= "2"
					class="swiper-class"
					@swiper="onSwiper"
					@activeIndexChange = "activeIndexChange"
			>
				<swiper-slide v-for="item in partnerCaseList" :key="item.id">
					<img
							:src="item.pcPicture"
							alt=""
							style="width: 100%;cursor:pointer"
							@click="clickSwiperItem(item)"
					/>
				</swiper-slide>
			</swiper>
			<div class="swiper-text1">{{partnerCaseList[swiperCurrent] ? partnerCaseList[swiperCurrent].title : ''}}</div>
			<div class="swiper-text2">{{partnerCaseList[swiperCurrent] ? partnerCaseList[swiperCurrent].content : ''}}</div>
			<div class="swiper-buttom" @click="router.push(`/solutionsChildren/${partnerCaseList[swiperCurrent].id}`)">查看详情</div>
			<div style="height: 49px;"></div>
		</div>
	</div>
</template>

<script setup>

import { AllPartnerCase } from "@/api/solutions";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue"; // swiper 所需组件
import { Pagination, A11y } from "swiper"; // 分页器
import "swiper/swiper-bundle.css";
import {onMounted, ref} from "vue";
import {useRouter} from "vue-router";

const router = useRouter();

const modules = [ Pagination, A11y];
let useSwiper = ref(null);
const onSwiper = (swiper) => {
	useSwiper = swiper; // 初始化swiper
};

onMounted(()=>{
	getPartnerCase();
	swiperCurrent.value = 0
})

const partnerCaseList = ref([]);
// 获取合作案例
const getPartnerCase = async () => {
	try {
		let res = await AllPartnerCase();
		if (res.code === 200) {
			partnerCaseList.value = res.data;
		}
	} catch (error) {
		console.log(error);
	}
};

let swiperCurrent = ref(0); // 当前轮播图
// 轮播图切换
const activeIndexChange = (swiper) => {
	swiperCurrent.value = swiper.realIndex
	/*if(swiper.activeIndex - 2 < 0){
		swiperCurrent.value = partnerCaseList.value.length - 1
	}else if (swiper.activeIndex - 2 == partnerCaseList.value.length){
		swiperCurrent.value = 0
	}else{
		swiperCurrent.value = swiper.activeIndex - 2
	}*/
}

// 点击轮播图
const clickSwiperItem = (item) => {
	// sort为后管配置的顺序，按照1、2、3递增
	const index = item.sort - 1
	const length = partnerCaseList.value.length
	if(swiperCurrent.value == 0){
		if(index == length - 1) {
			useSwiper.slidePrev()
		}else if(index > swiperCurrent.value){
			useSwiper.slideNext();
		}else{
			const id = partnerCaseList.value[swiperCurrent.value].id
			router.push(`/solutionsChildren/${id}`)
		}
	}else if(swiperCurrent.value == length - 1){
		if(index == 0){
			useSwiper.slideNext();
		}else if(index < swiperCurrent.value) {
			useSwiper.slidePrev()
		}else{
			const id = partnerCaseList.value[swiperCurrent.value].id
			router.push(`/solutionsChildren/${id}`)
		}
	}else{
		if(index < swiperCurrent.value) {
			useSwiper.slidePrev()
		}else if(index > swiperCurrent.value){
			useSwiper.slideNext();
		}else{
			const id = partnerCaseList.value[swiperCurrent.value].id
			router.push(`/solutionsChildren/${id}`)
		}
	}
}

</script>

<style scoped lang="less">
.page{
	width: 1920px;
	margin: 0 auto;
	.slougan-list {
		display: flex;
		justify-content: space-around;
		margin-top: 103px;
		.slougan {
			display: flex;
			flex-direction: column;
			align-items: center;
			&:hover {
				cursor: pointer;
			}
			.icon {
				width: 58px;
				height: 58px;
			}
			.icon-text1 {
				margin-top: 35px;
				height: 27px;
				line-height: 27px;
				font-family: PingFang SC;
				font-weight: bold;
				font-size: 28px;
				color: #000000;
			}
			.icon-text2 {
				margin-top: 13px;
				height: 21px;
				line-height: 21px;
				font-family: PingFang SC;
				font-weight: 400;
				font-size: 22px;
				color: #000000;
			}
		}
	}
	.goods-type {
		height: 604px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-content: space-between;
		flex-wrap: wrap;
		margin-top: 110px;
		margin-bottom: 155px;
		.goods-type-workstation {
			padding-top: 34px;
			padding-left: 57px;
			width: 843px;
			height: 290px;
			background-image: url("../images/workstation.png");
			background-size: cover;
			cursor: pointer;
			&:hover .goods-type-button {
				background: #000000;
				color: #ffffff;
				opacity: 1;
				border: 0px;
				line-height: 40px;
			}
		}
		.goods-type-desktop {
			padding-top: 36px;
			padding-left: 28px;
			width: 406px;
			height: 290px;
			background-image: url("../images/desktop.png");
			background-size: cover;
			cursor: pointer;
			&:hover .goods-type-button {
				background: #000000;
				color: #ffffff;
				opacity: 1;
				border: 0px;
				line-height: 40px;
			}
		}
		.goods-type-server {
			padding-top: 36px;
			padding-left: 62px;
			width: 406px;
			height: 290px;
			background-image: url("../images/server.png");
			background-size: cover;
			cursor: pointer;
			&:hover .goods-type-button {
				background: #000000;
				color: #ffffff;
				opacity: 1;
				border: 0px;
				line-height: 40px;
			}
		}
		.goods-type-onedesktop {
			padding-top: 36px;
			padding-left: 36px;
			width: 406px;
			height: 290px;
			background-image: url("../images/onedesktop.png");
			background-size: cover;
			cursor: pointer;
			&:hover .goods-type-button {
				background: #000000;
				color: #ffffff;
				opacity: 1;
				border: 0px;
				line-height: 40px;
			}
		}
		.goods-type-notebook {
			padding-top: 36px;
			padding-left: 28px;
			width: 406px;
			height: 290px;
			background-image: url("../images/notebook.png");
			background-size: cover;
			cursor: pointer;
			&:hover .goods-type-button {
				background: #000000;
				color: #ffffff;
				opacity: 1;
				border: 0px;
				line-height: 40px;
			}
		}
		.goods-type-text1 {
			height: 23px;
			line-height: 23px;
			font-family: PingFang SC;
			font-weight: bold;
			font-size: 24px;
			color: #000000;
		}
		.goods-type-text2 {
			margin-top: 19px;
			height: 22px;
			line-height: 22px;
			font-family: PingFang SC;
			font-weight: 400;
			font-size: 22px;
			color: #000000;
		}
		.goods-type-button {
			margin-top: 16px;
			width: 130px;
			height: 40px;
			line-height: 36px;
			box-sizing: border-box;
			text-align: center;
			border-radius: 20px;
			border: 2px solid #666666;
			opacity: 0.7;
			font-family: PingFang SC;
			font-weight: bold;
			font-size: 16px;
			color: #010101;
		}
	}
	.aftersale {
		margin-top: 109px;
		width: 100%;
		background-color: #fff;
		padding-top: 59px;
		padding-bottom: 92px;
		cursor: pointer;
		.aftersale-container{
			width: 1282px;
			margin: 0 auto;
			.aftersale-img {
				width: 338px;
				height: 55px;
				margin-left: 18px;
			}
			.aftersale-text {
				margin-top: 16px;
				margin-left: 22px;
				height: 23px;
				line-height: 23px;
				font-family: PingFang SC;
				font-weight: 400;
				font-size: 22px;
				color: #000000;
			}
			.aftersale-list {
				margin-top: 55px;
				display: flex;
				justify-content: space-between;
				padding-left: 23px;
				padding-right: 22px;
				.aftersale-li {
					text-align: center;
					img {
						width: 338px;
						height: 427px;
					}
					.aftersale-li-text1 {
						margin-top: 32px;
						height: 19px;
						line-height: 19px;
						font-family: PingFang SC;
						font-weight: bold;
						font-size: 20px;
						color: #000000;
					}
					.aftersale-li-text2 {
						margin-top: 9px;
						height: 17px;
						line-height: 17px;
						font-family: PingFang SC;
						font-weight: 400;
						font-size: 16px;
						color: #000000;
					}
				}
			}
		}
	}
	.about-us {
		margin: 134px auto 148px;
		display: block;
		width: 1298px;
		height: 448px;
		cursor: pointer;
	}
	.case {
		//width: 100%;
		background-color: #fff;
		img {
			margin-top: 80px;
			margin-left: 337px;
			width: 338px;
			height: 55px;
		}
		.case-title {
			margin-top: 16px;
			margin-left: 341px;
			font-family: PingFang SC;
			font-weight: 400;
			font-size: 22px;
			color: #000000;
		}
	}
	:deep(.swiper-class){
		padding-top: 76px;
		width: 1281px;
		margin: 0 auto;
		.swiper-pagination-bullets{
			position: relative !important;
			bottom: -29px !important;
		}
		.swiper-pagination-bullet{
			width: 16px;
			height: 16px;
			background: #999999;
			border-radius: 50%;
		}
		.swiper-pagination-bullet-active{
			width: 16px;
			height: 16px;
			background: #000000;
			border-radius: 50%;
		}
	}
	.swiper-text1{
		margin-top: 36px !important;
		margin: 0 auto;
		width: 1052px;
		height: 31px;
		line-height: 31px;
		text-align: center;
		font-family: PingFang SC;
		font-weight: 400;
		font-size: 32px;
		color: #000000;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.swiper-text2{
		margin-top: 23px !important;
		margin: 0 auto;
		width: 1052px;
		height: 18px;
		line-height: 18px;
		text-align: center;
		font-family: PingFang SC;
		font-weight: 400;
		font-size: 18px;
		color: #808490;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.swiper-buttom{
		margin-top: 18px !important;
		margin: 0 auto;
		width: 240px;
		height: 60px;
		line-height: 60px;
		background: #000000;
		border-radius: 10px;
		text-align: center;
		font-family: PingFang SC;
		font-weight: 400;
		font-size: 24px;
		color: #FFFFFF;
		cursor: pointer;
	}
	.swiper{
		padding-bottom: 29px;
	}
	.swiper-pagination{
		position: relative !important;
		bottom: -29px !important;
	}
	.swiper-pagination-bullet{
		margin-left: 22px !important;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background: #999999;
	}
	.swiper-pagination-bullet-active{
		background: #000000;
	}
}
</style>